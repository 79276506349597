/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.attr.data.goto";
exports.namespace = namespace;

// var jsonRender = require("../interface/jsonRender.js");
// var uai = require("../interface/interface.js");
// var events = require("../../../uam/events.js");

exports.define = {
    namespace: namespace,
    description: "The ability to use data-goto to fire navigation to a page.",
    fields: {
        "_attr": {
            type: "object",
            description: "The attributes to add to the anchor tag."
        }
    }
}

/**
 * Adds a anchor link tag to the page.
 * @param {*} options The json objects and properties to render.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

    throw new Error("This is not a renderable control");

}

//for any click event of an attribute of data-goto we will navigate to the page UNLESS the element clicked is a button or a tag and that attrivute does not exist in the clicked element.

document.body.addEventListener("click", function (e) {

    if (e.target.hasAttribute("data-goto")) {

        var goto = e.target.getAttribute("data-goto");
        // console.log("Navigating to: ", goto);

        if (e.target.tagName.toLowerCase() == "button" || e.target.tagName.toLowerCase() == "a") {
            if (!e.target.hasAttribute("data-goto")) {
                return;
            }
        }

        //if the first character is a "/" than add the domain parts to it
        if (goto.charAt(0) == "/") {
            goto = window.location.origin + goto;
        }

        //if the first character is a "#" than just navigate to the hash
        if (goto.charAt(0) == "#") {
            window.location.hash = goto;
            return;
        }

        window.location.href = goto;
    }

});
/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

/*
<a target="_blank" href="${href}" class="app">
    <div class="icon-cage bg-blur-white" tabindex="0">
      <i title="${name}" style="background-image:url(${logo})">&nbsp;</i>
    </div>
    <p class="title">Push Past Pain</p>
</a>
*/

var jsonRender = require("../../uat/src/interface/jsonRender.js");
var interface = require("../../uat/src/interface/interface.js");

var define = {
    namespace: "ac.app.shortcut",
    title: "An App Shortcut",
    description: "Displays an app shortcut with a logo, title, and link.",
    fields: {
        "name": {
            type: "string",
            description: "The name of the app.",
            required: true
        },
        "logo": {
            type: "uri",
            description: "The logo of the app.",
            required: true
        },
        "href": {
            type: "uri",
            description: "The link to the app.",
        }
    }
}; module.exports.define = define;


/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
exports.render = async function (options) {

    // var appInner = [{
    //     "n": "div",
    //     "c": "icon-cage bg-blur-75-black test",
    //     "tabindex": "0",
    //     "i": {
    //         "n": "i",
    //         "title": options.name,
    //         "style": `background-image:url(${options.logo})`
    //     }
    // },
    // {
    //     "n": "p",
    //     "c": "title",
    //     "i": options.name
    // }];

    // if (!("href") in options) {
    //     return {
    //         "n": "div",
    //         "c": "app",
    //         "i": appInner
    //     };
    // } else {
    //     return {
    //         "n": "a",
    //         "target": "_blank",
    //         "href": options.href,
    //         "c": "app",
    //         "i": appInner
    //     };
    // }

    var app = {
        "n": "div",
        "c": "col-12 col-md-6 d-inline-block",
        i: {
            "n": "a",
            "target": "_blank",
            "href": options.href,
            "c": "border-0 bg-75-black bg-100-black-hover rounded tiny-outline m-3 row ",
            "i": [
                {
                    "n": "div",
                    "c": "col-4 p-3",
                    "i": {
                        "n": "img",
                        "src": options.logo,
                        "alt": options.name,
                        "class": "image-fluid border-0 outline-0",
                        "style": "max-height: 4rem;"
                    }
                },
            {
                "n": "div",
                "c": "col",
                "i": {
                    "n": "p",
                    "c": "title p-3 text-white fs-4 text-start",
                    "i": options.name
                }
            }
          ]
        }
    };

    return app;

}
// /**
//  * Saves the code from the iframe.
//  * Called by UATools.
//  * @param {*} name The name of the control.
//  * @param {*} control The control to save.
//  * @param {*} repo The repo (used to send back), with the prevalidation results.
//  * @property {*} repo.success The success object (is this field ready to be saved).
//  * @property {*} repo.data The actual data object that will be saved. JSON encodable only (no functions or promises).
//  * @property {*} repo.errors The error's applied to the object. Should be an array, can have more than one item.
//  * @property {*} repo.errors.input If appliable, the direct input that caused the erorr - it must be an object. If input is not provided the control will be highlighted.
//  * @property {*} repo.errors.input.id The id of the input field, if applicable.
//  * @property {*} repo.errors.input.name The name of the input field if applicable.
//  * @property {*} repo.errors.type The type of error that occured.
//  *  - Supports: "validation" - The input or field or control is invalid
//  *  - Supports: "thowable" - Processing this field caused a throwable to error out.
//  * @property {*} repo.errors.message The message to display to the user.
//  * @returns The repo object with the data to save
//  */
// async function save(name, control, repo) {

//   // console.log("Trying save", {
//   //   name, control, repo
//   // });

//   //get the span
//   var span = control.querySelector("span");
//   repo.data = span.innerText;
//   return repo;

// } module.exports.save = save;

// /**
//  * Loads the control with data.
//  * @param {*} name The name of the control.
//  * @param {*} control The control itself (including placeholder tag).
//  * @param {*} data The data to load into the control.
//  */
// async function load(name, control, data) {

//   //get the span
//   var span = control.querySelector("span");
//   span.innerText = data;

// } module.exports.load = load;
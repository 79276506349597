/* {"ns":"universe.functions.resolve", "v":1, "i": false} */

// const { resolveInclude } = require("ejs");

/**
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for resolve file paths to app context.
 * 
 */

var namespace = "generateHash";

// var uuid = require("uuid");
// var hash = require("./hashString.js");

/**
 * Generates a hash from a string.
 * @param {*} str The string to hash.
 * @returns The hash.
 */
function hashString(str) {
    let hash = 0;

    if (str.length === 0) return hash;

    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32bit integer
    }

    return hash;
} module.exports.hashString = hashString;


module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: hashString,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
           

            // var uuid = generateHash();
            // console.log("Generated UUID", uuid);
            return false;

        }
    }]
};
/* {"ns":"universe.module.scafolding", "v":1, "i": false} */
/**
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Loads and activates specific persistant globals.
 * 
 * This will allow for easy passing of expected application objects.
 * This object makes it easy for intellisense to operate.
 * 
 * It should be noted that access to this object should be closely guarded.
 * 
 *  * Required Config Vars
 * 
 */
var namespace = "universe.app.scafolding";


function randomName(i) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.";

    for (var x = 0; x < i; x++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

//create a random varable name to store the global object
var globalName = randomName(10);
globalName += randomName(10);
globalName += randomName(10);
globalName += randomName(10);

var $ = global[globalName] || (global[globalName] = {});
module.exports = $;

/**
 * TODO:
 * Log all global objects stored here, they must be degined like config.
 * This will enable extensions to request access to registered global objects.
 * While preventing access to other objects stored here.
 */

// console.log(`Universe Scaffolding registerd at ${globalName}.`);
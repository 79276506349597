/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */
// var vanJsDatePicker = require("vanillajs-datepicker");

var vanJsDatePicker = false;
try {
  vanJsDatePicker = require("vanillajs-datepicker");
  // console.log("Vaniillajs-datepicker is", require("vanillajs-datepicker"));
  // vanJsDatePicker = require("vanillajs-datepicker"); 

} catch (error) {
  console.error("Vanillajs-datepicker is not available.", error);
}

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");
var uae = require("../interface/element.js");
var convertToAccountingDate = require("../../../uam/functions/convertToAccountingDate.js").function;
var describeAccountingDate = require("../../../uam/functions/describeAccountingDate.js").function;

module.exports.define = {
  namespace: "ua.select.date",
  title: "Date Time Picker",
  description: "A date time picker control.",
  control: true,
  prevalidate: true,
  supportServer: false,
  fields: {
    "name": {
      type: "string",
      description: "The name of the control.",
      required: true
    },
    "title": {
      type: "string",
      description: "The title of the control.",
      required: true
    },
    "required": {
      type: "boolean",
      description: "Whether or not the control is required.",
      addtouae: true
    },
    "icon": {
      type: "string",
      description: "The icon of the control.",
      required: false
    },
    "autohide": {
      type: "boolean",
      description: "Whether or not to autohide the control.",
      default: true
    },
    "buttonClass": {
      type: "string",
      description: "The class of the button.",
      default: "btn"
    },
    "clearButton": {
      type: "boolean",
      description: "Whether or not to show the clear button.",
      default: true
    },
    "todayButton": {
      type: "boolean",
      description: "Whether or not to show the today button.",
      default: true
    },
    "datesDisabled": {
      type: "array",
      description: "The dates that are disabled.",
    },
    "min": {
      type: "string",
      description: "The minimum selectable date. Date will be converted from a string to the javascript date object.",
      // default: "000000000000",
      addtouae: true
    },
    "max": {
      type: "string",
      description: "The maximum selectable date. Date will be converted from a string to the javascript date object.",
      addtouae: true
    },
    "daysOfWeekDisabled": {
      type: "array",
      description: "The days of the week that are disabled. 0:Sunday - 6:Saturday, up to 6 items.",
      required: false,
      addtouae: true
    },
    "daysOfWeekHighlighted": {
      type: "array",
      description: "The days of the week that are highlighted. 0:Sunday - 6:Saturday, up to 6 items.",
      required: false
    },
    "enableOnReadonly": {
      type: "boolean",
      description: "Whether or not to enable the control on readonly.",
      default: false
    },
    "value": {
      type: "string",
      description: "The value of the control. It can be \"today\" or a date string. If the value is not a valid date string, an error will be thrown.",
      required: false
    }, 
    "inputClass": {
      type: "string",
      description: "The class of the input."
    }, 
    "inputStyle": {
      type: "string",
      description: "The style of the input."
    }, 
    "description": {
      type: "inner",
      description: "The helpful text at the bottom of the control."
    }, 
    "weekStart": {
      type: "number",
      description: "The day of the week that the week starts on.",
      default: 0
    },
    "todayHighlight": {
      type: "boolean",
      description: "Whether or not to highlight today.",
      default: true
    }
  },
  errors: {
    "minDate": {
      title: "Minimum Date",
      description: "The selected date is before the minimum date.",
      isValidation: true
    },
    "maxDate": {
      title: "Maximum Date",
      description: "The selected date is after the maximum date.",
      isValidation: true
    },
    "dateDisabled": {
      title: "Out of Range",
      description: "The selected date is disabled.",
      isValidation: true
    },
    "vanillajsdatepicker": {
      title: "Missing Library",
      description: "The vanillajs-datepicker library is not available.",
      isValidation: false
    },
    "dateDisabled": {
      title: "Out of Range",
      description: "The selected date is disabled.",
      isValidation: true
    },
    "required": {
      title: "Required",
      description: "Please select a date.",
      isValidation: true
    },
    "invalidDate": {
      title: "Invalid Date",
      description: "The date provided could not be converted to a Date object.",
      isValidation: true
    }, 
    "dateNotAllowable": {
      title: "Date Not Allowable",
      description: "The datepicker control rejected the date provided more than (3) times and will not accept it. This can be due to one of the rules (min, max, daysOfWeekDisabled, etc.).",
      isValidation: true
    }
  },
  passthrough: {
    except: ["inner", "type", "id", "inputclass", "inputstyle"]
  }
}; //module.exports.define = define;

var mainErrors = uae.getErrors(exports.define);

async function render(options) {
 
  // console.log("Vanillajs-datepicker is", vanJsDatePicker);

  if (!(vanJsDatePicker)) {
    mainErrors.vanillajsdatepicker.throw();
  }

  var randomName = uai.generateRandomID(12);

  var _input = { 
    "n": "div",
    "id": randomName,
    "class": "ua-datepicker-holster",
    "title": options.title,
    "type": "text"
  };

  // if ("min" in options) {
  //   _input.min = convertDateFormat(options.min);
  // }

  // if ("max" in options) {
  //   _input.max = convertDateFormat(options.max);
  // }

  if ("value" in options) {

    try {

      //if the value (to lower case and trimmed) = today - than its fine
      if (options.value == "") {
      } else if (options.value === undefined) {
      } else if (options.value == null) {
      } else if (options.value.toLowerCase().trim() == "today") {
        // console.log("The date is today.");
      } else {
        newDate = new Date(options.value);
    
        if (isNaN(newDate.getTime())) {
          mainErrors.invalidDate.throw();
        }
      }
    } catch (error) {
      console.info("The date is", options.value);
      mainErrors.invalidDate.throw(error);
    }

    _input["data-date"] = options.value;

  }

  /** corrections for quick values for controls */
  //corrections for type=date
  if (options.type == "date") {
    //if max or min is in options and equal to now auto set the value
    if ("max" in options) {
      if (options.max == "now") {
        options.max = new Date().toISOString().split("T")[0];
      }
    }

    if ("min" in options) {
      if (options.min == "now") {
        options.min = new Date().toISOString().split("T")[0];
      }
    }
  }

  try {
    _input = await jsonRender.pass({...options}, {..._input}, ["name", "class", "icon", "style"]);
  } catch (error) {
    console.error(`ua.datetime.picker Error: ${error}`);
  }

  if ("inputclass" in options) {
    _input.class += " " + options.inputclass;
  }

  if ("inputstyle" in options) {
    _input.style = options.inputstyle;
  }

  var element = {
    "n": "div",
    "name": options.name,
    "c": "form-group pb-3",
    "i": [{
      "n": "label",
      "for": options.name,
      "c": "pb-2 fs-4",
      "i": options.title
    }, {
      "n": "div",
      "c": "ua-datepicker-container",
      "i": [{
        "n": "div",
        "for": randomName,
        "c": "pb-2 fs-4 ua-datepicker-title",
        "i": ""
      }, _input]
    }]
  }

  if ("icon" in options) {
    element.i[0].i = [{
          "n": "i",
          "class": options.icon
        }, " ",  element.i[0].i];
  }

  // element.i[1].i = _input;

  if ("description" in options) {
    element.i.push({
      "n": "div",
      "id": `${randomName}-description`,
      "c": "form-text",
      "i": options.description
    });
  }
 
  var domElement = await jsonRender.render(element);
  var input = domElement.querySelector(".ua-datepicker-holster");
  var title = domElement.querySelector(".ua-datepicker-title");

  //if onv 

  // console.log("The elements are", {
  //   domElement: domElement,
  //   input: input
  // });

  // input.addEventListener("focus", focus);
  // input.addEventListener("focusout", focusOut);

  var datePickerOptions = {
    autohide: options.autohide,
    format: {
        toValue(date, format, locale) {
            // let dateObject;

            // console.log("The date is", date);
            
            return convertToAccountingDate(date);

            // return dateObject;
        },
        toDisplay(date, format, locale) {
            // let dateObject;
            
            return describeAccountingDate(convertToAccountingDate(date));

            // return dateObject;
        }
    },
  }

  if ("buttonClass" in options) {
    datePickerOptions.buttonClass = options.buttonClass;
  }

  if ("clearButton" in options) {
    datePickerOptions.clearButton = options.clearButton;
  }

  if ("todayButton" in options) {
    datePickerOptions.todayButton = options.todayButton;
  }

  if ("datesDisabled" in options) {
    datePickerOptions.datesDisabled = options.datesDisabled;
  }

  if ("daysOfWeekDisabled" in options) {
    datePickerOptions.daysOfWeekDisabled = options.daysOfWeekDisabled;
  }

  if ("daysOfWeekHighlighted" in options) {
    datePickerOptions.daysOfWeekHighlighted = options.daysOfWeekHighlighted;
  }

  if ("enableOnReadonly" in options) {
    datePickerOptions.enableOnReadonly = options.enableOnReadonly;
  }

  if ("weekStart" in options) {
    datePickerOptions.weekStart = options.weekStart;
  }

  if ("todayHighlight" in options) {
    datePickerOptions.todayHighlight = options.todayHighlight;
  }

  if ("min" in options) {
    datePickerOptions.minDate = options.min;
  }

  if ("max" in options) {
    datePickerOptions.maxDate = options.max;
  }

  var myDatePicker = new vanJsDatePicker.Datepicker(input, datePickerOptions); 

  var updateDate = () => {
    // var curDate = myDatePicker.getDate();
    // if (curDate) {
    //   title.innerHTML = describeAccountingDate(convertToAccountingDate(curDate));
    //   title.style.display = "inline-block";
    // } else {
    //   title.innerHTML = "";
    //   title.style.display = "none";
    // }

    save(options.name, domElement, {});
  };

  //on clcik if input
  input.addEventListener("click", updateDate);
  input.addEventListener("focusout", updateDate);

  // console.log("Flatpickr is", flatpickr);

  // flatpickr.default(input, {
  //   enableTime: true,
  //   dateFormat: "YmdHi",
  //   altFormat: "F j, Y h:i K",
  //   disableMobile: true,
  //   minDate: options.minDate ? convertDateFormat(options.minDate) : null,
  //   maxDate: options.maxDate ? convertDateFormat(options.maxDate) : null,
  //   enable: [
  //     function (date) {
  //       // This function checks if the date is within the selectable times.
  //       let dayOfWeek = date.getDay();
  //       let dayStr = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"][dayOfWeek];
  //       let times = options.selectableTimes && options.selectableTimes[dayStr];
  //       if (!times) return false; // If no times defined for this day, disable selection.
        
  //       let start = new Date(date);
  //       let end = new Date(date);
  //       start.setHours(times.start.substr(0, 2), times.start.substr(2, 4), 0);
  //       end.setHours(times.end.substr(0, 2), times.end.substr(2, 4), 0);
        
  //       return true;
  //       return date >= start && date <= end;

  //     }
  //   ]
  // });

  return domElement;
} module.exports.render = render;

function validation(control) {
  var input = control.querySelector(".ua-datepicker-holster");

  var selectedDate = input.datepicker.getDate();

  var errors = [];

  // if (!(input.checkValidity())) {
  //   errors.push({
  //     type: "validation",
  //     message: input.validationMessage
  //   });
  // }

  if ((control.getAttribute("required")) && !(selectedDate)) {
    errors.push({
      ...mainErrors.required,
      type: "errorObject"
    });
  }

  //using the control's min, max, and selectableTimes properties
  
  var min = control.getAttribute("min");
  var max = control.getAttribute("max");
  var daysOfWeekDisabled = control.getAttribute("daysOfWeekDisabled");

  if (min && input.value < min) {
    errors.push({
      ...mainErrors.minDate,
      type: "errorObject"
    });
  }

  if (max && input.value > max) {
    errors.push({
      ...mainErrors.maxDate,
      type: "errorObject"
    });
  }

  if (daysOfWeekDisabled) {
    if (daysOfWeekDisabled.includes(new Date(input.value).getDay())) {
      errors.push({
        ...mainErrors.dateDisabled,
        type: "errorObject"
      });
    }
  }

  return errors;
}

async function save(name, control, repo) {
  var input = control.querySelector(".ua-datepicker-holster");
  var title = control.querySelector(".ua-datepicker-title");
  var selectedDate = input.datepicker.getDate();

  //double check to make sure the calendar didn't update properly
  if (!(selectedDate)) {
    //does the title have a value? - if so it will be January 1, 2021 (format is MMMM D, YYYY)
    if (title.innerHTML) {
      selectedDate = new Date(title.innerHTML);
    }
  }

  // var curDate = myDatePicker.getDate();
  repo.errors = validation(control);
  // console.log("The errors are", repo.errors);

  //update the display
  if (selectedDate) {
    title.innerHTML = describeAccountingDate(convertToAccountingDate(selectedDate));
    title.style.display = "inline-block";
  } else {
    title.innerHTML = "";
    title.style.display = "none";
  }

  control.classList.remove("is-invalid");
  control.classList.remove("is-valid");

  repo.errors = validation(control);
  if (repo.errors.length > 0) {
    repo.success = false;
    control.classList.add("is-invalid");
  } else {
    repo.success = true;
    control.classList.add("is-valid");
  }

  //convert date to YYYY-MM-DD
  selectedDate = selectedDate.toISOString().split("T")[0];
  repo.data = selectedDate;
  return repo;
} module.exports.save = save;


async function load(name, control, data) {
  var input = control.querySelector(".ua-datepicker-holster");
  var title = control.querySelector(".ua-datepicker-title");

  //ensure that I won't update the control if the data is empty and it's already empty
  if (!(data)) {
    if (title.innerText == "") {
      return;
    }
  }

  // var newDate = new Date(data);
  // console.log("Attempting to set the date to", {
  //   // newDate: newDate,
  //   data: data,
  //   input: input,
  //   datepicker: input.datepicker
  // });

  //if data is in the format YYYY-MM-DD
  if (data.length == 10) {
    // ensure it has -'s
    if (data[4] == "-" && data[7] == "-") {
      data = `${data}T00:00:00`;
    }
    // data = `${data}T00:00:00`;
  }

  var newDate = null;

  try {
    if (data == "") {
    } else if (data === undefined) {
    } else if (data == null) {
    } else if (data.toLowerCase().trim() == "today") {
      // console.log("The date is today.");
    } else {
      newDate = new Date(data);
  
      if (isNaN(newDate.getTime())) {
        mainErrors.invalidDate.throw();
      }
    }
  } catch (error) {
    console.info("The date is", data);
    mainErrors.invalidDate.throw(error);
  }


  //The calendar control doesn't always update the date immediately
  // I wish you could know when the date picker is ready.

  // console.log("Datepicker", input.datepicker.active);

  var triedTimes = 0;
  var updateCal = () => {
    triedTimes++;
    input.datepicker.setDate(new Date(data));
    // console.log("Updated", {
    //   data: data,
    //   updatedDate: input.datepicker.getDate(),
    //   theNewDate: new Date(data)
    // });

    if (input.datepicker.getDate() == undefined) {
      if (triedTimes > 3) {
        mainErrors.dateNotAllowable.throw();
        return;
      }
      setTimeout(updateCal, 100);
    }

    save(name, control, {});

  };

  setTimeout(updateCal, 100);

  try {
    title.innerHTML = describeAccountingDate(convertToAccountingDate(data));
    title.style.display = "inline-block";
  } catch (error) {
    
  }

  // input.datepicker.setDate(new Date(data));
  // console.log("Updated", input.datepicker.getDate());

  // input.value = data;
} module.exports.load = load;

// function convertDateFormat(dateStr) {
//   return `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6, 8)}T${dateStr.slice(8, 10)}:${dateStr.slice(10, 12)}`;
// }

// function applySelectableTimes(instance, selectableTimes) {
//   if (!selectableTimes) return;

//   let config = instance.config;
//   let enabledTimes = [];

//   let dayOfWeek = instance.selectedDate.getDay();
//   let dayStr = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"][dayOfWeek];
//   let dayConfig = selectableTimes[dayStr] || selectableTimes["*"];

//   if (dayConfig) {
//     if (dayConfig.start && dayConfig.end) {
//       let start = `${dayConfig.start.slice(0, 2)}:${dayConfig.start.slice(2, 4)}`;
//       let end = `${dayConfig.end.slice(0, 2)}:${dayConfig.end.slice(2, 4)}`;
//       enabledTimes.push({ from: start, to: end });
//     } else if (dayConfig === false) {
//       enabledTimes = [];
//     }
//   }

//   config.enable = enabledTimes;
// }

/* {"ns":"universe.functions.resolve", "v":1, "i": false} */

// const { resolveInclude } = require("ejs");

/**
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for resolve file paths to app context.
 * 
 */

var namespace = "verifyEmail";
var validateRegEx = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}/;


function verifyEmail(email) {

    // console.info("Verifying email", email);

    if (validateRegEx.test(email)) {
        // console.info("Email is valid");
        return true;
    } else {
        // console.info("Email is invalid");
        return false;
    }

}

module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: verifyEmail,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
           
            var validEmails = ["this@that.com", "thisisvalid@email.com"];
            var invalidEmails = ["this@", "@123.com", "8.8.4.4", "this@tha", "this@192.168.0"];

            var success = true;

            for(var i in validEmails) {
                if (!(verifyEmail(validEmails[i]))) {
                    // console.log("Failed (isValid)?", validEmails[i]);
                    success = false;
                }
            }

            for(var i in invalidEmails) {
                if (verifyEmail(invalidEmails[i])) {
                    // console.log("Failed (not valid?)", invalidEmails[i]);
                    success = false;
                }
            }

            // console.log("Success", success);
            return success;

        }
    }]
};
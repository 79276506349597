var _ = require("../../../uam/module.js")(module.exports, {
    namespace: "ua.random",
    title: "Random Object",
    description: "Showcases a diffrent object everytime the page is refreshed.",
    editable: true,
    fields: {
        inner: {
           type: "inner",
           title: "Items",
           description: "The random element to show.",
           required: true
        }
    }, 
    passthrough: {
        except: ["inner"]
    },
    supportServer: false
});

var events = require("../../../uam/events.js");
var uai = require("../interface/interface.js");
var uae = require("../interface/element.js");

/**
 * Renders one of the inner objects randomly.
 * @param {*} options The jsonObject to render (should use fields)
 */
exports.render = async function (options) {

    var rtn = {
        n: "div",
        c: "ua-random",
        i: false
    };

    //return only one random item, if random item is an object - or only one item - return it
    if (Array.isArray(options.inner)) {
        var randomIndex = Math.floor(Math.random() * options.inner.length);
        // console.log("randomIndex", randomIndex); 
        rtn.i = options.inner[randomIndex];
    } else {
        rtn.i = options.inner;
    }

    return rtn;

}
/* {"ns":"universe.functions.resolve", "v":1, "i": false} */

// const { resolveInclude } = require("ejs");

/**
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for resolve file paths to app context.
 * 
 */

var namespace = "generateHash";

// var uuid = require("uuid");
var hashString = require("./hashString.js").function;

/**
 * Generate a hash from a JSON object.
 * @returns The hashed String.
 */
function generateHashFromObject(item) {
    var toHash = JSON.stringify(item).trim();
    return hashString(toHash);
}


module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: generateHashFromObject,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
           

            // var uuid = generateHash();
            // console.log("Generated UUID", uuid);
            return false;

        }
    }]
};
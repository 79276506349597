/**
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Recursively converts short hand object name to complete object name.
 * 
 */

var namespace = "shorthand";

/**
 * Recursively converts short hand to full name.
 * Array objects are also converted.
 * Now operates safely on arrays and non-objects types.
 * Warns: if the object is not an object or array.
 * @param {Object} obj the object that uses a shorthand
 * @param {*} sh the shorthand object. A shorthand name must be unique.
 * @returns {Object} the object with the shorthand converted to full name.
 */
function convertShortHand(obj, sh) {

    //am I an array
    if (Array.isArray(obj)) {
        //let's convert each object in the array
        for (var i = 0; i < obj.length; i = i + 1) {
            obj[i] = convertShortHand(obj[i], sh);
        }
        return obj;
    } else if (!(typeof obj === "object")) {
        console.warn("Only an object may be converted from shorthand.", 
            {
                typeOfObj: typeof obj,
                obj: obj,
                typeOfSh: typeof sh,
                sh: sh
            } 
        );

        return obj;

        // throw new Error ("Only an object may be converted from shorthand.")
    }

    //convert SH to expansion
    var expansion = {};
    for (var name in sh) {
        var val = sh[name];

        if (Array.isArray(val)) {
            for (var i = 0; i < val.length; i = i+1) {

                //check if the value is in the expansion array
                if (val[i] in expansion) {
                    throw new Error(`${val[i]} has already been declared.`);
                }

                expansion[val[i]] = name;
            }
        } else if (typeof val === "object") {
            // console.log("Warning: Shorthand values cannot be an object.", {
            //     type: typeof val,
            //     name: name,
            //     value: val,
            //     obj: obj,
            //     sh: sh
            // })
            throw new Error("Shorthand values cannot be an object.");
        } else {
            expansion[val] = name;
        }

    }

    // console.log("Result of expanding the shorthand object.", {
    //     expansion: expansion,
    //     sh: sh
    // });

    var _obj = null;

    if (Array.isArray(obj)) {
        //clone array
        _obj = [...obj]
    } else {
        _obj = {...obj};
    }

    // var _obj = {...obj};
    $rtn = expandObject(_obj, expansion);

    // console.log("Result of expanding the object.", {
    //     rtn: $rtn
    // });

    return $rtn;

}

/**
 * Converts the object, recursively to its expansion.
 * @param {*} obj The Json object to expand.
 * @param {*} expansion The expansion alias object which must be a object of var alias names with the value of the orignal object name.
 * @returns {*} The expanded object.
 */
function expandObject(obj, expansion) {

    // console.log("expandObject", {
    //     obj: obj,
    //     expansion: expansion
    // });

    // if (obj === undefined) throw new Error("Object cannot be undefined.");

    if (typeof obj === "object") {
        for (var key in obj) {

            if (key in expansion) {
                // console.log("Found a key that needs to be expanded.", {
                //     key: key,
                //     expansion: expansion
                //     });

                //replace the key with the expansion[key]
                obj[expansion[key]] = obj[key];
                delete obj[key];

                // console.log("After replacing the key with the expansion.", {
                //     key: key,
                //     expansion: expansion,
                //     obj: obj
                // });

                //expand the new object
                obj[expansion[key]] = expandObject(obj[expansion[key]], expansion);

            } else {
                
                //expand the old object
                obj[key] = expandObject(obj[key], expansion);

            }
            
        }

        return obj;
    } else if (Array.isArray(obj)) {
        // console.log("obj is an array")
        var i = 0;
        while (i > obj.length) {
            obj[i] = expandObject(obj[i], expansion);
            i = i + 1;
        }
        return obj;
    } else {
        return obj;
    }

}

module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: convertShortHand,
    tests: [{
        namespace: `${namespace}.test`,
        must: {this: true, that: false},
        run: async () => {
            var sh = {
                "this": ["t", "a"],
                "that": ["b", "c"]
            };

            var json = {
                "a": true,
                "b": false
            };

            $rtn = convertShortHand(json, sh);
            return $rtn;
        }
    }, {
        namespace: `${namespace}.noConversion`,
        must: {this: true, that: false},
        run: async () => {
            var sh = {
                "this": ["t", "a"],
                "that": ["b", "c"]
            };

            var json = {this: true, that: false};

            $rtn = convertShortHand(json, sh);
            return $rtn;
        }
    },  {
        namespace: `${namespace}.array`,
        must: true,
        run: async () => {
            var sh = {
                "this": ["t", "a"],
                "that": ["b", "c"]
            };

            var json = [
                {
                    "class": "text-center",
                    "inner": [
                        "Your ",
                        {
                            "data": [
                                "shopping",
                                "searching",
                                "dreaming",
                                "gaming",
                                "streaming",
                                "coding",
                                "browsing",
                                "sharing",
                                "creating"
                            ],
                            "inner": [
                                "coding_"
                            ],
                            "namespace": "ua.a.typeon"
                        },
                        " experience just got better."
                    ],
                    "namespace": "p"
                }
            ];

            // console.log("Is the test an array?", Array.isArray(json));

            $rtn = convertShortHand(json, sh);
            
            // console.log("Return", {
            //     rtn: $rtn,
            //     isArr: Array.isArray($rtn)
            // });

            return Array.isArray($rtn);

        }
    }] 
};
/*!
* (C) 2024. Justin K Kazmierczak. All Rights Reserved.
*
* Build for The Universe.
* Subject to The Universe Terms of Service and Privacy Policy.
* 
* Built for Universe App Tools.
* 
* This module marks a placeholder for the ua element.
* 
*/

var f = require("../scripts/f.js");
var uae = require("../interface/element.js");
var uai = require("../interface/interface.js");

var define = {
    namespace: "ua.element.validation.error",
    title: "UAE Error Message",
    description: "An error message from a ua element.",
    fields: {
        "title": {
            type: "string",
            description: "The title of the error.",
            default: "Looks like we need your help."
        },
        "doc": {
            type: "object",
            description: "The Save() document that failed validation.",
            required: true
        }, "inner": {
            type: "inner",
            description: "Additional content to display on top of the errors."
        }
    },
    passthrough: {
        except: ["inner", "doc", "id", "name"]
    }
}; module.exports.define = define;


/**
 * Renders the error message.
 * @param {*} options The jsonObject to render.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

    console.log("Error Options: ", options);

    var header = [{
        "n": "h1",
        "i": [
            {
                "n": "i",
                "class": "bi bi-exclamation-circle"
            },
            {
                "n": "span",
                "i": ["&nbsp;", options.title]
            }
        ]
    }];

    if ("inner" in options) {
        header.push(options.inner);
    }


    var errorList = [];
    var alt = false;
 
    for (var key in options.doc) {

        //if the key is success, skip
        if (key === "success") {
            continue;
        }

        //if the success = true, skip
        if (options.doc[key].success) {
            continue;
        }
        
        var element = document.querySelector(`uae [name="${key}"]`);

        var goTo = false;

        if (element) {

            //does the element have an id, if not generate one
            var id = element.id || uai.generateRandomID(24);
            element.id = id;

            goTo = {
                "n": "ua.a.incenter",
                "c": "text-reset btn btn-text-white text-hover-black",
                "goto": `#${id}`,
                "i": [
                    {
                        "n": "i",
                        "class": "bi bi-box-arrow-right"
                    }
                ]
            }

        }

        //remove duplicate messages
        var messages = [];

        // console.log("Errors", options.doc[key].errors);

        for (var i = 0; i < options.doc[key].errors.length; i++) {

            // console.log("Showing a new error", {
            //     "key": key,
            //     "i": i,
            //     "type": options.doc[key].errors[i].type,
            //     error: options.doc[key].errors[i]
            // });

            switch (options.doc[key].errors[i].type) {
                case "errorObject":
                    var message = `${options.doc[key].errors[i].title}: ${options.doc[key].errors[i].description} (${options.doc[key].errors[i].namespace}, type: ${options.doc[key].errors[i].type})`;
                    if (!messages.includes(message)) {
                        messages.push(message);
                    }
                break;
                default:
                    var message = `${options.doc[key].errors[i].message} (${options.doc[key].errors[i].type})`;
                    if (!messages.includes(message)) {
                        messages.push(message);
                    }
                break;
            }
        }
        
        var labelText = element.querySelector("label").innerText;

        var errorClass = alt ? "bg-black text-white" : "bg-white text-black";
        alt = !alt;

        var messagesDivs = [];

        for (var i = 0; i < messages.length; i++) {
            messagesDivs.push({
                "n": "div",
                "c": "p-2",
                "i": messages[i]
            });
        }

        var errEle = {
            "n": "li",
            "class": `p-2 rounded mb-3 tiny-outline ${errorClass}`,
            "i": [
                {
                    n: "div",
                    i: [{
                        "n": "strong",
                        "i": labelText
                    }]
                },
                {
                    n: "div",
                    i: [messagesDivs]
                },
                // messages.join(" "),
                " "
            ]
        };

        if (goTo) {
            //add after div
            errEle.i[0].i.push(goTo);
        }

        errorList.push(errEle);

    }

    var error = { 
        "n": "div",
        "id": "alertError",
        "class": "alert alert-primary tiny-outline",
        "role": "alert",
        "style": "display: block;",
        "i": [
            header,
            {
                "n": "ul",
                "class": "list-unstyled",
                "i": errorList 
            }
        ]
    }

    return error;


}

/**
 * 
<div id="alertError" class="alert alert-primary tiny-outline box-shadow" style="display: block;" role="alert">
    <h1><i class="bi bi-exclamation-circle"></i> ${title}</h1>
        <ul class="list-unstyled">
           <!-- alternate between bg-info and bg-warning -->
            <li class="p-2 rounded mb-3 text-shadow tiny-outline bg-info text-white"><strong class="text-capitalize">${This would be the element's label.}</strong>: {$validation message or messages} <a href="${this needs to either be a lookup on the name or a button that scrolls to the objects center}" class="text-reset">(<i class="bi bi-box-arrow-right"></i> go to) </a></li>
            <li class="p-2 rounded mb-3 text-shadow tiny-outline bg-warning text-white"><strong class="text-capitalize">${This would be the element's label.}</strong>: {$validation message or messages} <a href="${this needs to either be a lookup on the name or a button that scrolls to the objects center}" class="text-reset">(<i class="bi bi-box-arrow-right"></i> go to) </a></li>
        </ul>
</div>

                        {
    "success": false,
    "year": {
        "success": true,
        "data": "2022",
        "errors": [],
        "namespace": "ua.select.dropdown"
    },
    "make": {
        "success": true,
        "data": "Chevrolet",
        "errors": [],
        "namespace": "ua.select.dropdown"
    },
    "model": {
        "success": true,
        "data": "BoltEV",
        "errors": [],
        "namespace": "ua.select.dropdown"
    },
    "color": {
        "success": false,
        "data": "",
        "errors": [
            {
                "input": {
                    "id": "dubUF0ddWZM9j4",
                    "name": ""
                },
                "message": "Please fill out this field.",
                "type": "validation"
            },
            {
                "type": "validation",
                "message": "Please fill out this field."
            }
        ],
        "namespace": "ua.select.dropdown"
    },
    "type": {
        "success": true,
        "data": "Sedan",
        "errors": [],
        "namespace": "ua.select.group"
    },
    "lastDetail": {
        "success": true,
        "data": "",
        "errors": [],
        "namespace": "ua.input"
    }
}
 */
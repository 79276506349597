/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

/*
<div class="form-group pb-3">
    <label for="${name}" class="pb-2 fs-4">${title}</label>
    <div class="input-group">
        <div class="input-group-prepend">
            <div class="input-group-text text-color rounded-0 rounded-start"><i class="${icon}"></i></div>
        </div><input id="${name}" class="form-control is-invalid" title="${title}">
    </div>
</div>
*/

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");

var define = {
  namespace: "ua.updater",
  title: "UA Updater.",
  description: "An on the fly updater for the user interface.",
  // supportServer: false,
  control: true,
  fields: {
    "name": {
      type: "string",
      description: "The name of the control.",
      required: true
    },
    "uanamespace": {
      type: "uanamespace",
      description: "The namespace of the control.",
      addtouae: true,
      required: true
    },
    "inner": {
      type: "inner",
      description: "The placeholder until the control is updated with your request",
      required: true
    }, 
    "override": {
      type: "object",
      description: "Override the information passed to the control with a defualt value.",
      default: false,
      addtouae: true
    }
  }
}; module.exports.define = define;


/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
exports.render = async function (options) {

  return options.inner;

  // return ele;

}

// /**
//  * Saves the code from the iframe.
//  * Called by UATools.
//  * @param {*} name The name of the control.
//  * @param {*} control The control to save.
//  * @param {*} repo The repo (used to send back), with the prevalidation results.
//  * @property {*} repo.success The success object (is this field ready to be saved).
//  * @property {*} repo.data The actual data object that will be saved. JSON encodable only (no functions or promises).
//  * @property {*} repo.errors The error's applied to the object. Should be an array, can have more than one item.
//  * @property {*} repo.errors.input If appliable, the direct input that caused the erorr - it must be an object. If input is not provided the control will be highlighted.
//  * @property {*} repo.errors.input.id The id of the input field, if applicable.
//  * @property {*} repo.errors.input.name The name of the input field if applicable.
//  * @property {*} repo.errors.type The type of error that occured.
//  *  - Supports: "validation" - The input or field or control is invalid
//  *  - Supports: "thowable" - Processing this field caused a throwable to error out.
//  * @property {*} repo.errors.message The message to display to the user.
//  * @returns The repo object with the data to save
//  */
// async function save(name, control, repo) {

//   // console.log("Trying save", {
//   //   name, control, repo
//   // });

//   //get the span
//   var span = control.querySelector("span");
//   repo.data = span.innerText;
//   return repo;

// } module.exports.save = save;

/**
 * Loads the control with data.
 * @param {*} name The name of the control.
 * @param {*} control The control itself (including placeholder tag).
 * @param {*} data The data to load into the control.
 */
async function load(name, control, data) {

  //get the span
  // var span = control.querySelector("span");
  // span.innerText = data;

  var render = {
    namespace: control.getAttribute("uanamespace"),
    ...data
  };

  //get override if it exists
  var override = control.getAttribute("override");
  if (override) {
    override = JSON.parse(override);
    render = {
      ...render,
      ...override
    };
  }

  control.innerHTML = "";
  control.appendChild(await jsonRender.render(render));

} module.exports.load = load;
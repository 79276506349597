/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * (C) 2022-2024 Justin K Kazmierczak, The Universe, The EGT Universe, LLC. All Rights Reserved.
 * 
 * This notice may not be removed.
 * 
 * Subject to The Universe Terms of Service and Privacy Policy.
 * https://egtuniverse.com/legal
 * 
 * This is licensed to members in good standing of The Universe App Artisan Program.
 * 
 * Source code access and modifcation is subject to the terms of the license
 * and may not be shared or distributed without the express written consent of
 * The Universe. 
 * 
 * All instances of this software must be registered with The Universe.
 * Domains (and subdomains or wildcard domain) must be registered with The Universe.
 * 
 * Use source code responsibly.
 * 
 */
var config = require("../config.js");
var body = document.querySelector("body");
var getStackTrace = require("../../../uam/functions/generateStacktrace.js").function;

// /**
//  * This shows the user that the page is loading.
//  * If your app needs to take manual control use the manual function.
//  */
// async function init() {

// } module.exports.init = init;

function manual() {
    //remove interval
    if (myinterval) {
        clearTimeout(myinterval);
    }

    //reset to still loading
    show();
} module.exports.manual = manual;

function show() {

    var err = new Error();

    // console.log("Loading...", err);

    body.classList.remove("loading-more-than4");
    body.classList.add("loading");
    body.classList.remove("loaded");
} module.exports.show = show;

function hide() {


    var err = new Error();
    // console.log("Loaded!", err);

    setTimeout(() => {
        body.classList.add("loaded");
    }, 50);
    body.classList.remove("loading");
    body.classList.remove("loading-more-than4");
} module.exports.hide = hide;

var myinterval = null;

// function NormalLoadingType() {
    
// } module.exports.NormalLoadingType = NormalLoadingType;

var loadingType = "page";
if (config.loadingType) {
    loadingType = config.loadingType;
}

if (loadingType === "page") {
    document.addEventListener("DOMContentLoaded", function () {
        // let assetsLoaded = false;


        // var myinterval = null;
        function showLoading() {
            show();
            myinterval = setTimeout(() => {
                body.classList.add("loading-more-than4");
            }, 50);
        }

        function hideLoading() {
            // if (assetsLoaded) {
                myinterval = setTimeout(() => {
                    hide();
                }, 50);

                // //remove the timeout
                // if (myinterval) {
                //     clearTimeout(myinterval);
                // }

        
            // }
        }

        showLoading();

        // Call hideLoading when all page resources have finished loading
        window.addEventListener("load", function () {
            // assetsLoaded = true; // Mark that assets have loaded
            hideLoading();
        });
    });
} else if (loadingType === "manual") {
    //manual loading
    hide();
}

/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.repeater";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uae = require("./templates/ua.element.js");
var uai = require("../interface/interface.js");

var required = ["data", "plate"];
exports.required = required;

exports.define = {
  supportServer: true,
  namespace: namespace,
  description: "Renders a repeatable element by the provided data.",
  title: "Repeat",
  fields: {
    "data": {
      type: "array", 
      description: "The data to repeat the element with.",
    }, 
    "src": {
      type: "string",
      description: "The source of the data to repeat.",
    },
    "inner": {
      type: "inner",
      description: "The template to repeat.",
      required: true
    },
    "sort": {
      type: "object",
      description: "The key to sort the data by.",
      required: false,
      fields: {
        "key": {
          type: "string",
          description: "The key to sort the data by."
        },
        "order": {
          type: "string",
          description: "The order to sort the data by.",
          options: ["asc", "desc", "random", "ascend", "descend", "ascending", "descending"]
        }
      }
    },
    "displayOrder": {
      type: "object",
      description: "The order of the data to repeat.",
      required: false,
      fields: {
        "list": {
          type: "array",
          description: "The order of the data to repeat. Use \"*\" to repeat any items not listed, if a wild card is not present items will be appended after the listed items.",
        },
        "key": {
          type: "string",
          description: "The key to use to order."
        }
      }
    }
  },
  required: required//,
  // supportServer: false
}

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  // var options = uae.GetOptions(json, required);

  // console.log("Repeat Input", {
  //   inner: options.inner.inner + "",
  //   });

  if ("src" in options) {
    var dataFromSource = await uai.getObject(options.src, "json");

    if ("data" in options) {
      // join them
      options.data = options.data.concat(dataFromSource);
    } else {
      options.data = dataFromSource;
    }

  }

  // console.log("Repeat Data", options.data);


  var rows = [];

  //Passthrough the data provided as a row of data {};
  if (Array.isArray(options.data)) {


    //is sort in options, sort the data
    if ("sort" in options) {
      var sort = options.sort;
      var key = sort.key;
      var order = sort.order;

      if (order == "asc" || order == "ascend" || order == "ascending") {
        options.data.sort((a, b) => (a[key] > b[key]) ? 1 : -1);
      } else if (order == "desc" || order == "descend" || order == "descending") {
        options.data.sort((a, b) => (a[key] < b[key]) ? 1 : -1);
      } else if (order == "random") {
        options.data.sort(() => Math.random() - 0.5);
      }

    }

    //is displayOrder in options, order the data, keep the rest in order.
    if ("displayOrder" in options) {
      var displayOrder = options.displayOrder;
      var list = displayOrder.list;
      var key = displayOrder.key;

      var ordered = [];
      var unordered = [];

      for (var i = 0; i < list.length; i++) {
        var item = list[i];
        for (var j = 0; j < options.data.length; j++) {
          var row = options.data[j];
          if (row[key] == item) {
            ordered.push(row);
          }
        }
      }

      for (var i = 0; i < options.data.length; i++) {
        var row = options.data[i];
        if (!ordered.includes(row)) {
          unordered.push(row);
        }
      }

      


    }

  
      
      for (var i = 0; i < options.data.length; i++) {

        var row = options.data[i];
        // console.log("Repeater Working Row", {
        //   row: row,
        //   inner: options.inner
        // });

        var rowRender = await addItem(options.inner, row);
        // console.log("Row Render", rowRender);
        rows.push(rowRender);

      }

  } else {

    //provide the context to the inner template.
    rows.push(await addItem(options.inner, options.data));

  }

  // console.log("Repeat Output", {
  //   rows: rows, 
  //   row1: rows[0]
  // });

  // console.log("The repeater rows", rows);

  //No need to wrap it as an array will generate a document fragment.
  // if (bybass) {
  //   return rows;
  // } else {
    return await jsonRender.render(rows);
  // }

}

async function addItem(inner, data) {

  // console.log("Adding Item", {
  //   inner: inner,
  //   data: data
  // });

  return await jsonRender.recursivelyHandleLiterals(inner, data);
}

/** 
{
  "n": "h1",
  "i": "${title}"
}
*/


// options.displayOrder = {
//   list: ["x","y", "z", "*", "c", "b", "a"],
//   key: "id"
// }

// options.data = [{
//   "id": "a",
//   "title": "A123"
// },  {
//   "id": "d",
//   "title": "D123"
// }, {
//   "id": "e",
//   "title": "E123"
// }, {
//   "id": "f",
//   "title": "F123"
// }, {
//   "id": "x",
//   "title": "X123"
// }, {
//   "id": "y",
//   "title": "Y123"
// }]
  
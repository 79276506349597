var _ = require("../../../uam/module.js")(module.exports, {
    namespace: "api.payments.info",
    title: "Pre-checkout, showcases a cart or line items.",
    description: "Shows a cart or line items before checkout.",
    errors: {
        "invalidResponse": {
            title: "Invalid Response",
            description: "The response from the payment provider was invalid."
        }
    },
    fields: {
        "checkout-id": {
            type: "string",
            title: "Checkout ID",
            description: "The checkout ID."
        },
        "items": {
            type: "list",
            title: "Line Items to Checkout",
            description: "The line items to checkout.",
            required: false,
            fields: {
                "name": {
                    type: "string",
                    title: "Name",
                    description: "The name of the item.",
                    required: true
                },
                "price": {
                    type: "number",
                    title: "Price",
                    description: "The price of the item.",
                    required: true
                },
                "deposit": {
                    type: "number",
                    title: "Deposit",
                    description: "The deposit of the item.",
                    required: false
                },
                "quantity": {
                    type: "number",
                    title: "Quantity",
                    description: "The quantity of the item.",
                    required: true
                },
                "id": {
                    type: "string",
                    title: "ID",
                    description: "The ID of the item.",
                    required: false
                },
                "type": {
                    type: "string",
                    title: "Type",
                    items: ["one-time-physical","one-time-digital", "recurring-monthly", "recurring-yearly", "recurring-daily", "recurring-weekly", "deposit", "duty"],
                    description: "The type of the item.",
                    default: "one-time",
                }
            }
        }
    },
    supportServer: false
});

async function render(options) {

} _.render = render;
/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.dialog";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");
// var uae = require("./templates/ua.element.js");


var required = ["inner", "image"]; 
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "Dialog Module",
  description: "A fullscreen dialog module.",
  fields: {
    "inner": {
      type: "inner", 
      description: "The body of the dialog box."
    },
    "title": {
      type: "inner", 
      description: "The title of the dialog box."
    },
    "footer": {
      type: "inner", 
      description: "The footer of the dialog box."
    }, 
    "allowclose": {
      type: "boolean",
      description: "If true, the dialog will have a close button.",
      default: true
    }
  },
  required: required
} 



/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  // var options = json;

  // var myid = false;

  var modalHeader = false;
  
  //If I options.allowclose = false, we won't render a close button or the id.

  if ("allowclose" in options) {
    if (!(options.allowclose)) {
      modalHeader = {
        n: "div",
        c: "modal-header p-1",
        i: {
          n: "h1",
          c: "modal-title d-flex align-items-center",
          i: [
            options.title
          ]
        }
      }
    }
  }

  if (!(modalHeader)) { 
    // myid = uai.generateRandomID(8);
    modalHeader = {
      n: "div",
      c: "modal-header p-1",
      i: [
        {
          n: "div",
          c: "col-10",
          i: {
            n: "h1",
            c: "modal-title d-flex align-items-center",
            i: [
              options.title
            ]
          }
        },
        {
          n: "div",
          c: "col-2 text-end",
          i: {
            n: "button",
            c: "btn btn-text-white text-hover-black modal-dia-close", //text-shadow box-shadow",
            i: {
              n: "i",
              c: "bi bi-x-circle-fill"
            }
          }
        }
      ]
    }
  }

  var modal = {
    n: "div",
    c: "modal show d-block bg-blur-75-black ",
    role: "dialog",
    tabindex: "-1",
    "aria-modal": "true",
    i: {
      n: "div",
      c: "modal-dialog modal-dialog-centered modal-dialog-scrollable",
      i: {
        n: "div",
        c: "modal-content",
        i: [
          modalHeader,
          {
            n: "div",
            c: "modal-body m-3",
            i: options.inner
          },
          {
            n: "div",
            c: "modal-footer p-2",
            i: options.footer
          }
        ]
      }
    }
  };


  // if (myid == false) {
  //   //than output and render the modal as provided.
  //   return await jsonRender.convertJSONToDOM(modal, {
  //     PassthroughOptions: options
  //   });
  // } else {
    //otherwise, we have some work to do.
    //attach our event listerner to the button
    // modal.id = myid;
    
    modal = await jsonRender.convertJSONToDOM(modal, {
      PassthroughOptions: options
    });

    var btn = modal.querySelector(".modal-dia-close");

    // console.log("Dialog button is:", {
    //   btn: btn,
    //   modal: modal
    // });

    btn.addEventListener("click", function() {
      modal.remove();
    });

    return modal;

  // }

}

/** 
 * 
 * 
 * 
 * <div class="modal show" id="modelLegal" tabindex="-1" aria-labelledby="modelReset" aria-modal="true" role="dialog" style="z-index: 100000; display: block;">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header p-1">
            <div class="col-10">
              <h1 class="modal-title d-flex align-items-center" id="modalReset">
                <span class="mybrand d-inline-block" style="width:4rem; height:4rem; margin-right:1rem;"></span> ${options.title}
              </h1>
            </div>
            <div class="col-2 text-end"><button class="btn btn-text-info"><i class="bi bi-x-circle-fill"></i></button></div>
          </div>
          <div class="modal-body m-3">
            

            ${options.inner}

          </div>
          <div class="modal-footer p-2">

            ${options.footer}
            <!-- <button type="button" class="btn btn-underline-primary" onclick="HideModal('modelLegal')">Accept</button> -->
          </div>
        </div>
      </div>
    </div>
*/

/* {"ns":"universe.functions.resolve", "v":1, "i": false} */

// const { resolveInclude } = require("ejs");

/**
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for resolve file paths to app context.
 * 
 */

var namespace = "generateRandomID";

/**
 * Generates a random id based on the requested length.
 * @param {*} length The amount of characters to generate. 
 * @param {*} underscore Whether to include an underscore in the ID.
 * @returns The random ID.
 */
function generateRandomID(length, underscore = true, numbersonly = false) {

    var characters = '_f0ghi1jkl2mno3pqr4stu5vwx6yzA7BCD8EFG9HIJaKLMbNOPcQRSdTUVeWXYZ';

    if (numbersonly) {
        characters = '94025423109845678192304578901928465772937192674910';
    }

    if (!underscore) {
        characters = characters.replace("_", "");
    }

    let randomID = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomID += characters.charAt(randomIndex);
    }

    var prefixCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    if (numbersonly) {
        prefixCharacters = '92846577293719267490987654123';
    }

    let prefix = '';
    for (let i = 0; i < 2; i++) {
      const randomIndex = Math.floor(Math.random() * prefixCharacters.length);
      prefix += prefixCharacters.charAt(randomIndex);
    }

    return prefix + randomID + "";
}


module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: generateRandomID,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
           
            //test sleep
            var id = [];
            var num = 100;

            while(num > 0) {
                id.push(generateRandomID(8));
                // console.info(`${num}: ${id}`);
                num--;
            }

            console.info("Generated IDs for the test:", id);

            if (id[3].length == 8) {
                return true;
            } else {
                return false;
            }

        }
    }]
};